export default class Video {
  constructor() {
    this.buttons = document.querySelectorAll('[data-video]')
    this.modal = document.querySelector('.video')
    this.frame = this.modal.querySelector('.video__frame')
    this.overlay = this.modal.querySelector('.video__overlay')

    this.buttons.forEach(button => {
      button.addEventListener('click', () => this.open(button.dataset.video))
    })

    this.overlay.addEventListener('click', () => this.close())

    window.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') {
        this.close()
      }
    })
  }

  open(src) {
    this.frame.src = `${src}?autoplay=1&title=0&byline=0&portrait=0`
    this.modal.classList.add('video--active')
  }

  close() {
    this.modal.classList.remove('video--active')
    this.frame.src = ''
  }
}
